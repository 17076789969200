import React from 'react'
import '../styles/HeroSection.module.scss'

const HeroSection: React.FC = () => {
  return (
    <section className="hero-section">
      <div className="terminal">
        <pre>
          <code>
            {`
            $ npm install bibot
            BiBot v3.2.1 initialized...
            Connecting to AI Network...
            Launching chat session...
            ` }
          </code>
        </pre>
      </div>
      <h2>AI Chatbot As A Service</h2>
      <p>Customize your chatbot&apos;s behavior with easy-to-use APIs.</p>
      <button
        className="cta-button"
        onClick={() => window.open('https://bibot.app/admin-portal', '_blank', 'noopener noreferrer')}
      >
        Start Free Trial
      </button>

    </section>
  )
}

export default HeroSection
