import React from 'react'
import Home from './pages/Home'
import './styles/global.module.scss'
import { BiBot } from 'bibot'

const App: React.FC = () => {
  return (
    <div className="App">
      <Home />

      <BiBot 
        clientId='2ba7bbb2-84b3-4f90-acf1-a283d68be294'
        test={true}
        internalUserOnly={{ internalUseOnly: false}}
      />
    </div>
  )
}

export default App
