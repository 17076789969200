import React from 'react'
import '../styles/DemoSection.module.scss'

const DemoSection: React.FC = () => {
  return (
    <section className="demo-section" id="demo">
      <h2>Try BiBot Now</h2>
      <div className="chat-window">
        {/* Here, you can embed a mockup or a live demo of your chatbot */}
        <p>User: How can you help me?</p>
        <p>BiBot: I can assist with customer support, FAQs, and much more!</p>
      </div>
      <button
        className="cta-button"
        onClick={() => window.location.href = 'mailto:bibot@thecloudsecllc.com?subject=Talk to BiBot&body=Hi, I would like to know more about BiBot.'}
      >
        Talk to BiBot
      </button>

    </section>
  )
}

export default DemoSection
