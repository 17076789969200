import React from 'react'
import '../styles/Header.module.scss'

const Header: React.FC = () => {
  return (
    <header className="header">
      <h1>BiBot DEMO PAGE</h1>
      <nav>
        <ul>
          <li>
            <a href="https://www.npmjs.com/package/bibot" target="_blank" rel="noopener noreferrer">
              Integration
            </a>
          </li>
          <li>
            <a href="https://bibot.app/#Features" target="_blank" rel="noopener noreferrer">
              Features
            </a>
          </li>
          <li>
            <a href="https://bibot.app/pricing" target="_blank" rel="noopener noreferrer">
              Pricing
            </a>
          </li>
          <li>
            <a href="mailto:bibot-demo@thecloudsecllc.com?subject=Request for Demo Credentials&body=Hi, I would like to request demo credentials for BiBot." >
              Request Demo Credentials
            </a>
          </li>

        </ul>
      </nav>
    </header>
  )
}

export default Header
