import React from 'react'
import '../styles/FeaturesSection.module.scss'

const features = [
  {
    title: 'AI-Powered',
    description: 'Integrate the latest AI capabilities to understand and respond to users.',
    icon: '⚙️'
  },
  {
    title: 'Highly Scalable',
    description: 'Handle thousands of concurrent chats with ease.',
    icon: '📈'
  },
  {
    title: 'Customizable',
    description: 'Personalize your chatbot\'s responses, look, and feel.',
    icon: '🎨'
  },
]

const FeaturesSection: React.FC = () => {
  return (
    <section className="features-section" id="features">
      <h2>Features</h2>
      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <span>{feature.icon}</span>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  )
}

export default FeaturesSection
